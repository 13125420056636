import { ApiClient } from 'modules/core/class/api/ApiClient';
import { AppNotification } from '../model';
import { DISCARDED_NOTIFICATIONS_KEY } from '../constants';
import { logger } from 'modules/core/logger';

export type ListNotificationResponse = Record<string, unknown>[];

export class AppNotificationService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'notifications';

    public async getNotifications(): Promise<AppNotification[]> {
        const notifications: AppNotification[] = await this.getNotificationsFromServer();
        const validNotifications = this.filterValidAndNotDiscarded(notifications);

        return validNotifications;
    }

    public async getNotificationsFromServer() {
        try {
            const result: ListNotificationResponse = await this.get();
            return result.map(n => AppNotification.from(n));
        } catch (error) {
            logger.error(error);
            return [];
        }
    }

    private getDiscardedNotificationIds() {
        const ids = JSON.parse(localStorage.getItem(DISCARDED_NOTIFICATIONS_KEY) ?? '[]');
        return new Set<string>(ids);
    }

    private setDiscardedNotificationIds(ids: Set<string>) {
        localStorage.setItem(DISCARDED_NOTIFICATIONS_KEY, JSON.stringify(Array.from(ids)));
    }

    private filterValidAndNotDiscarded(notifications: AppNotification[]) {
        const discardedIds = this.getDiscardedNotificationIds();

        return notifications.filter(n => n.isValid && !discardedIds.has(n.id));
    }

    public discardNotification(notification: AppNotification) {
        if (!notification.isDiscardable) return;

        const discardedIds = this.getDiscardedNotificationIds();
        discardedIds.add(notification.id);
        this.setDiscardedNotificationIds(discardedIds);
    }
}

export const appNotificationService = new AppNotificationService();
