import { CSVTemplate } from 'modules/dashboard/models/CSVTemplate';

export const handleCSVDownload = (csvTemplate: CSVTemplate, filename: string): void => {
    if (!csvTemplate) return;

    const csvLink = document.createElement('a');
    const data = csvTemplate.getCSVData();
    const headers = csvTemplate.getHeaders();

    const csvContent = [headers.join(','), ...data.map(row => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    csvLink.setAttribute('href', url);
    csvLink.setAttribute('download', filename);
    csvLink.click();

    window.URL.revokeObjectURL(url);
    csvLink.remove();
};
