export const NON_MARKETING_TEAMS = [
    'AFS',
    'AGL-FixedCosts-DE',
    'AGL-FixedCosts-IT',
    'AGL-FixedCosts-SP',
    'AGL-FixedCosts-UK',
    'AGL',
    'AMXL-EGY',
    'AMXL-KSA',
    'AMXL',
    'AMZL-EGY',
    'AMZL-KSA',
    'Air',
    'Amazon-Music',
    'AmazonStudiosLocalization',
    'AMOC-ATS-BBM',
    'AMOC-ATS-Janitorial',
    'AMOC-FR-Regulatory-Control-Provide',
    'AMPS',
    'AMZL-FixedCosts-AU',
    'AMZL-SP-and-E',
    'AMZL-UAE',
    'AMZL',
    'Annapurna-Labs-Inc',
    'AVP1-Goods',
    'Baby-Registry-Consumables',
    'Building-Base-Maintenance-Contracts',
    'Canteen-Expense',
    'DET3-Sort',
    'EU-ATS-SC-FC',
    'EU-TAP-OPM',
    'FACT-NonMarketing',
    'FC-Support',
    'FC-EGY',
    'FC-KSA',
    'FC-UAE',
    'FP-A',
    'FR-Accounting',
    'GCA-Finance',
    'GO',
    'GPOC',
    'GroceryTech',
    'GSF-Other',
    'GSF-TBC',
    'Immigration-NonMarketing',
    'IN-ASSLP-Security',
    'IndiaStore-NonMarketing',
    'INOPS-TAAS-VP-Services',
    'IT-EF',
    'ITES-Accounting',
    'Janitorial-CF-ASP',
    'Kitchen',
    'Linehaul',
    'MCF-fixed',
    'MCF-fulfilment',
    'MCF-GTM',
    'NA-AFS',
    'NA-Stores-US-Hardline',
    'NACF',
    'NASC-SouthSecurity-NonMarketing',
    'OAS_VM',
    'Other-Fixed-Costs',
    'OS-SP-Supporting',
    'Pest-Control',
    'PIT-rental',
    'PrivateBrands',
    'RME-Hotline-support',
    'SCs-EGY',
    'SCs-KSA',
    'SCs-UAE',
    'SCs',
    'Security-CF-ASP',
    'Shuttle-Expenses',
    'Statutory-reporting-AT-CZ-DE-SK',
    'Style',
    'SPOC-Receipts',
    'TR-Associates',
    'TS-Invoicing',
    'TwitchLeaseOutsourcing',
    'UK-EF',
    'Vending-Expense',
    'WFM',
    'WWGS-International',
    'WWGS-Marketing'
];

export const XCM_TEAM = 'XCM';
